<template>
  <div>
     <Slider />
     <Description section="cross-border" />
     <Strengths :strengths="strengths" />
     <Slogan />
     <TestimonialsPage />
  </div>
</template>

<script>
export default {
    name: "Cross-Border",
       components: {
       Slider: () => import('./sections/sliders/cross-border'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
    },
   computed: {
       strengths () {
         const data = [
                 {
                    image: 'Pie_chart.png',
                    title: 'Seamless Integrations',
                    text: 'We readily have APIs for 3rd party integrations making integration comfortable and easy with minimal effort.',
                    color: '#d29f2a',
                },
                {
                    image: 'Pie_chart.png',
                    title: 'Dynamism',
                    text: 'We are constantly exploring, testing and adding new features, so we can provide our customers with the best possible toolsets. By the time you see this message, all our systems will have morphed.',
                    color: '#d29f2a',
                },
             
                {
                    image: 'Note.png',
                    title: 'One-stop Shop Convenience',
                    text: 'Once registered as a customer you have access to all your payment needs on the go, using one unified platform.',
                    color: '#ffffff',
                },
                {
                    image: 'Note.png',
                    title: 'Advanced Fraud Detection',
                    text: 'We have a combination of automated fraud system monitoring tools that proactively flag high-risk activity, detect illegitimate transactional behavior online and provides alerts and analysis tools.',
                    color: '#ffffff',
                },
         ];
         return data;
       }

    },


}
</script>

<style>

</style>